// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import "../css/app.scss"

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import deps with the dep name or local files with a relative path, for example:
//
//     import {Socket} from "phoenix"
//     import socket from "./socket"
//
import "phoenix_html"
import {Socket} from "phoenix"
import NProgress from "nprogress"
import {LiveSocket} from "phoenix_live_view"
import 'alpinejs'
import { PhoenixLiveViewDropzone } from "phoenix_live_view_drop_zone";

let Hooks = {};
Hooks.PhoenixLiveViewDropzone = new PhoenixLiveViewDropzone();

Hooks.LoadFilledKarmaOnScroll = {
  mounted() {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if(entry.isIntersecting){
        this.pushEvent("load_more")
      }
    });

    observer.observe(this.el)
  }
}

Hooks.LoadArokiyaLogOnScroll = {
  mounted() {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if(entry.isIntersecting){
        this.pushEvent("load_more_arokiya_log")
      }
    });

    observer.observe(this.el)
  }
}

Hooks.LoadUniqueSchemeOnScroll = {
  mounted() {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if(entry.isIntersecting){
        this.pushEvent("load_more_unique_schemes")
      }
    });

    observer.observe(this.el)
  }
}


Hooks.RetailerDashboardRewardPageHook = {
  mounted() {
    console.log("RetailerDashboardRewardPageHook")

    let main_this = this; 
    let lsa_code = this.el.getAttribute("phx-data-lsacode")
    console.log("retailer hashed_lsa_code hook")
    console.log(lsa_code)

    var hashed_lsa_code = EncryptQS(lsa_code, "lp)k@oil")
    
    main_this.pushEvent("hashed_retailer_lsa_code", {hashed_lsa_code: hashed_lsa_code})

  }
}

Hooks.SeDashboardRewardPageHook = {
  mounted() {
    console.log("SeDashboardRewardPageHook")

    let main_this = this; 
    let empcode = this.el.getAttribute("phx-data-empcode")
    console.log("retailer hashed_empcode hook")
    console.log(empcode)

    var hashed_empcode = EncryptQS(empcode, "lp)k@oil")
    
    main_this.pushEvent("hashed_se_empcode", {hashed_empcode: hashed_empcode})

  }
}


function EncryptQS(str, key) {
        if (key == null || key.length <= 0) {
            return str
        }
        if (key.toString().length > 8)
            key = key.substring(0, 8);
        var prand = "";
        for (var i = 0; i < key.length; i++) {
            prand += key.charCodeAt(i).toString()
        }
        var sPos = Math.floor(prand.length / 5), preMult = prand.charAt(sPos) == '0' ? '1' : prand.charAt(sPos);
        preMult = preMult + prand.charAt(sPos * 2) + prand.charAt(sPos * 3) + prand.charAt(sPos * 4) + prand.charAt(sPos * 5);
        var mult = parseInt(preMult), incr = Math.ceil(key.length / 2), modu = Math.pow(2, 31) - 1;
        if (mult < 2) {
            return null
        }
        var salt = Math.round(Math.random() * 1000000000) % 100000000;
        prand += salt;
        while (prand.length > 10) {
            prand = (parseInt(prand.substring(0, 10), 10)).toString()
        } prand = (mult * prand + incr) % modu;
        var enc_chr = "", enc_str = "";
        for (var i = 0; i < str.length; i++) {
            enc_chr = parseInt(str.charCodeAt(i) ^ Math.floor((prand / modu) * 255));
            if (enc_chr < 16) {
                enc_str += "0" + enc_chr.toString(16)
            }
            else
                enc_str += enc_chr.toString(16);
            prand = (mult * prand + incr) % modu
        }
        salt = salt.toString(16);
        while (salt.length < 8)
            salt = "0" + salt;
        enc_str += salt;

        return enc_str 
    }


let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {hooks: Hooks, params: {_csrf_token: csrfToken}})

// Show progress bar on live navigation and form submits
window.addEventListener("phx:page-loading-start", info => NProgress.start())
window.addEventListener("phx:page-loading-stop", info => NProgress.done())

// connect if there are any LiveViews on the page
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)
window.liveSocket = liveSocket
